import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, useInView } from 'framer-motion';
import '../styles/SuccessStories.css';
import ComparisonChart from './ComparisonChart';
import GrowthChart from './GrowthChart';

function SuccessStories() {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const metrics = {
    withAI: {
      leads: 422,
      effectiveLeads: 90.5,
      contactedLeads: 76.2,
      appointments: 62.9,
      upperFunnel: 43.4
    },
    withoutAI: {
      leads: 676,
      effectiveLeads: 83.7,
      contactedLeads: 79.0,
      appointments: 26.4,
      upperFunnel: 17.5
    },
    growth: {
      monthOverMonth: 235,
      upperFunnelIncrease: 23
    }
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.8, 
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <motion.section 
      className="success-stories"
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={containerVariants}
    >
      <motion.h2 className="titleSucess" variants={itemVariants}>{t('success.title', 'A/B Analysis Results')}</motion.h2>
      
      <motion.div className="case-study" variants={itemVariants}>        
        <ComparisonChart metrics={metrics} />

        <motion.div className="narrative" variants={itemVariants}>
          <p>{t('success.narrative1', `Our AI-powered solution has revolutionized agency performance. With a staggering 148% increase in upper funnel conversion rate, agencies using our AI technology are outperforming their counterparts by more than double.`)}</p>
          <p>{t('success.narrative2', `This isn't just about numbers – it's about transforming how agencies operate, dramatically improving lead quality, and skyrocketing appointment rates.`)}</p>
        </motion.div>

        <GrowthChart growth={metrics.growth} />

        <motion.div className="key-findings" variants={itemVariants}>
          <h4>{t('success.keyFindings', 'Key Insights')}</h4>
          <ul>
            <li>{t('success.finding1', 'AI-powered agencies achieved a 43.4% upper funnel rate, compared to just 17.5% in traditional agencies')}</li>
            <li>{t('success.finding2', 'Appointment setting efficiency increased by 138%, from 26.4% to 62.9%')}</li>
            <li>{t('success.finding3', 'In the first month alone, agencies saw a phenomenal 235% growth')}</li>
            <li>{t('success.finding4', 'Consistent 23% month-over-month increase in upper funnel performance')}</li>
          </ul>
        </motion.div>

        <motion.div className="cta" variants={itemVariants}>
          <h4>{t('success.ctaTitle', 'Ready to Revolutionize Your Agency?')}</h4>
          <p>{t('success.ctaDescription', 'Join the AI revolution and see your agency\'s performance soar. Our technology doesn\'t just promise results – it delivers them.')}</p>
          <a href="/contact" className="cta-button">{t('success.ctaButton', 'Transform Your Agency Now')}</a>
        </motion.div>
      </motion.div>
    </motion.section>
  );
}

export default SuccessStories;

