import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { messages } from './locales'; // Importa las traducciones desde tu archivo locales.js

// Configuración de i18next
i18n
  .use(initReactI18next)
  .init({
    resources: messages, // Usa los mensajes importados
    lng: 'es', // Idioma por defecto
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false, // React ya hace el escape de valores por defecto
    },
  });

export default i18n;
