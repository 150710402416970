import React, { useRef, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  Title, 
  Tooltip, 
  Legend 
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ComparisonChart({ metrics }) {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        setChartWidth(chartRef.current.offsetWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const data = {
    labels: [t('success.effectiveLeads'), t('success.contactedLeads'), t('success.appointments'), t('success.upperFunnel')],
    datasets: [
      {
        label: t('success.withAI'),
        data: [
          metrics.withAI.effectiveLeads,
          metrics.withAI.contactedLeads,
          metrics.withAI.appointments,
          metrics.withAI.upperFunnel
        ],
        backgroundColor: 'rgba(0, 153, 204, 0.7)',
      },
      {
        label: t('success.withoutAI'),
        data: [
          metrics.withoutAI.effectiveLeads,
          metrics.withoutAI.contactedLeads,
          metrics.withoutAI.appointments,
          metrics.withoutAI.upperFunnel
        ],
        backgroundColor: 'rgba(102, 179, 255, 0.7)',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: chartWidth < 600 ? 'bottom' : 'top',
      },
      title: {
        display: true,
        text: t('success.comparisonTitle', 'AI vs Non-AI Agency Performance'),
        font: {
          size: chartWidth < 600 ? 14 : 16
        }
      },
    },
    scales: {
      x: {
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          font: {
            size: chartWidth < 600 ? 10 : 12
          }
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: chartWidth < 600 ? 10 : 12
          }
        }
      }
    }
  };

  return (
    <div ref={chartRef} style={{ height: chartWidth < 600 ? '300px' : '400px', width: '100%' }}>
      <Bar options={options} data={data} />
    </div>
  );
}

export default ComparisonChart;

