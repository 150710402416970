import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function GrowthChart({ growth }) {
  const { t } = useTranslation();

  const data = {
    labels: [t('success.month1'), t('success.month2'), t('success.month3')],
    datasets: [
      {
        label: t('success.upperFunnelGrowth'),
        data: [17.5, 40.5, 43.4], // Estimated growth based on the provided data
        borderColor: 'rgb(0, 153, 204)',
        backgroundColor: 'rgba(0, 153, 204, 0.5)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: t('success.growthTitle', 'Upper Funnel Growth Over Time'),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t('success.percentageLabel', 'Percentage (%)'),
        },
      },
    },
  };

  return (
    <div className="growth-chart">
      <Line options={options} data={data} />
      <div className="growth-highlights">
        <div className="highlight-card">
          <span className="highlight-value">+{growth.monthOverMonth}%</span>
          <span className="highlight-label">{t('success.momGrowth', 'First Month Growth')}</span>
        </div>
        <div className="highlight-card">
          <span className="highlight-value">+{growth.upperFunnelIncrease}%</span>
          <span className="highlight-label">{t('success.upperFunnelGrowth', 'Consistent Monthly Increase')}</span>
        </div>
      </div>
    </div>
  );
}

export default GrowthChart;

